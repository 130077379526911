import {useDispatch, useSelector} from "react-redux";
import callApi from "../../../common/api/Apiutils";
import {useAlert} from "../../../common/fieldfactory/alert/AlertContext";
import {loadNutrition} from "../../nutrition/logic/Reducer";
import {setGoals, setGoalWeight} from "./Reducer";

export const useReduxActions = () => {
    const handleChangeWeightGoal = useHandleChangeWeightGoal();
    const handleChangeGoalWeight = useHandleChangeGoalWeight();
    const handleSaveGoals = useHandleSaveGoals();
    return {
        handleChangeGoalWeight,
        handleChangeWeightGoal,
        handleSaveGoals
    }
}

const useHandleChangeWeightGoal = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setGoals(e))
    }
}

const useHandleChangeGoalWeight = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setGoalWeight(e.target.value))
    }
}

const useHandleSaveGoals = () => {
    const {showAlert, hideAlert} = useAlert();
    const dispatch = useDispatch();
    const {weightGoal, goalWeight} = useSelector(state => state.goal);
    const authorization = useSelector(state => state.default.authorization);
    return async () => {
        const newButtons = [
            {
                label: "Yes",
                onClick: async () => {
                    await callApi(`/fit/user/goals`, 'POST', {weightGoal : weightGoal , goalWeight : goalWeight}, {
                        Authorization: authorization,
                        service: 'UserService'
                    }, undefined, dispatch);

                    const response = await callApi(`/fit/nutrition`, 'GET', null, {
                        Authorization: authorization,
                        service: 'NutritionService'
                    }, undefined, dispatch);
                    if (response.error) {
                        showAlert(response.message);
                    }else {
                        dispatch(loadNutrition(response));
                    }

                }
            },
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to update the weight goal?', newButtons);
    }
}