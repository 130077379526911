import React from 'react'
import {Bar} from "react-chartjs-2";

const HorizontalBarGraph = ({ labels,graphSize, xScale , dataSet , animation = true}) => {
    const {width, height} = graphSize;
    const data = {
        labels: labels,
        datasets: [
            ...dataSet
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y', // Set indexAxis to 'y' for horizontal bars
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function(context) {
                        // Customize the tooltip label
                        console.log(context);
                        return `Value : ${context.raw}`;
                    },
                },
            },
        },
        interaction: {
            mode: 'nearest',
        },
        animation: {
            duration: animation ? 500 : 0,
        },
        title: {
            display: true,
            text: 'Chart.js Horizontal Bar Chart'
        },
        scales: {
            x: {
                beginAtZero: true,
                ...xScale
            },
            y: {
                ticks: {
                    display: true,
                    callback: function(value, index) {
                        const label = data.labels[index] || value;
                        const maxCharsPerLine = 10; // Define your line length limit

                        // Split label by space and reassemble into lines within maxCharsPerLine limit
                        const words = label.split(' ');
                        let currentLine = '';
                        const lines = [];
                        if(words.length === 1) {
                            return words;
                        }
                        words.forEach((word, i) => {
                            // Check if adding this word would exceed the line limit
                            if ((currentLine + word).length > maxCharsPerLine) {
                                lines.push(currentLine); // Push current line to lines array
                                currentLine = word; // Start a new line with the current word
                            } else {
                                // Append the word to the current line
                                currentLine += (currentLine ? ' ' : '') + word;
                            }

                            // Prevent adding the last word as a new line
                            if (i === words.length - 1 && currentLine) {
                                lines.push(currentLine.slice(0, -word.length)); // Remove the last word
                            }
                        });
                        if (lines[lines.length - 1].trim() === '') {
                            lines.pop();
                        }

                        return lines;
                    }}
            },
        },
    };

    return (
        <div style={{ position: 'relative', width: width, height: height }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default HorizontalBarGraph;
