import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Button, Card, DropDown, TextBox} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";
import GoalDoughnutGraph from "./GoalDoughnutGraph";


const WeightGoalComponent = () => {
    const {weightGoal, weightGoalDropDown, goalWeight, weightAtGoalSetting} = useSelector(state => state.goal);
    const {
        handleChangeWeightGoal,
        handleChangeGoalWeight,
        handleSaveGoals,
    } = useReduxActions();
    const {mobile} = useSelector(state => state.default);
    const {unit} = useSelector(state => state.setting);
    const {currentWeight, weightChangePerWeek} = useSelector(state => state.home.weightData);
    const [edit, setEdit] = useState(false);
    const graphSize = mobile ? '120px' : '250px';

    const style = {marginTop: '5px', marginBottom: '5px'};
    const progress = Math.ceil(((weightAtGoalSetting - currentWeight) / (weightAtGoalSetting - goalWeight)) * 100);

    const valueDisplay = () => {
        return (!mobile ? <div><h2 style={style}>{progress}%</h2><h3 style={style}>Progress</h3></div> :
                <div><h4 style={style}>{progress}%</h4><h5 style={style}>Progress</h5></div>
        )
    }

    const barColour = (weightGoal, weightChangePerWeek) => {
        // Calculate the absolute difference between the two values
        const difference = Math.abs(weightGoal - weightChangePerWeek);

        // If the difference is within 0.1, return green, otherwise return red
        if (difference <= 0.1) {
            return 'rgb(30, 131, 3)'; // Green color
        } else {
            return 'rgb(186, 0, 0)';  // Red color
        }
    };


    const xScale = {
        min: -1, // Set minimum value for the scale
        max: 1,  // Set maximum value for the scale
        ticks: {
            autoSkip: false,
            // Define the exact values for the X-axis ticks
            callback: function (value) {
                return `${value} ${unit}`; // Custom format for the labels
            },
            // Use a custom array of tick values
            values: [-1, -0.75, -0.5, -0.25, 0, 0.25, 0.5, 0.75, 1],
        },
    };

    const formatedGoalAtSetting = Number(weightAtGoalSetting).toFixed(2);
    const formatedCurrentWeight = Number(currentWeight).toFixed(2);
    const formatedGoalWeight = Number(goalWeight).toFixed(2);
    const dataSet = [{
        label: 'Change',
        data: [weightGoal, weightChangePerWeek],
        borderColor: ['rgb(54, 162, 235)', barColour(weightGoal, weightChangePerWeek)],
        backgroundColor: ['rgb(54, 162, 235)', barColour(weightGoal, weightChangePerWeek)],
    }]
    const labels = ['Goal', 'Actual'];
    return (
        <Card header={'Weight goal'}>
            <h5 style={{marginTop: '5px'}}>Weight goal progress</h5>
            <GoalDoughnutGraph
                mobile={mobile}
                progress={progress}
                doughnutGraphSize={graphSize}
                valueDisplay={valueDisplay()}
                graphSize={graphSize}
                formatedGoalAtSetting={formatedGoalAtSetting}
                unit={unit}
                formatedCurrent={formatedCurrentWeight}
                formatedGoal={formatedGoalWeight}
                xScale={xScale}
                dataSet={dataSet}
                labels={labels}
                doughnutLabel={'weight'}
                barGraphTitle={'Weight change per week'}
            />
            {edit ?
                (
                    <>
                        <TextBox type="text" id="name" label="Weight Goal" value={goalWeight}
                                 onChange={handleChangeGoalWeight}
                                 className="form-control" readOnly={false}/>
                        <DropDown options={weightGoalDropDown} onChange={handleChangeWeightGoal} value={weightGoal}
                                  label="Change by"/>

                        <Button
                            label={"Save"}
                            className="button mt-10"
                            onClick={handleSaveGoals}
                        />
                        <Button
                            label={"Cancel"}
                            className="button button-tertiary mt-10"
                            onClick={() => setEdit(!edit)}
                        />
                    </>
                )
                :
                (
                    <Button
                        label={"Edit"}
                        className="button mt-10"
                        onClick={() => setEdit(!edit)}
                    />
                )
            }
        </Card>
    )
}

export default WeightGoalComponent;