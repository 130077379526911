import {useDispatch, useSelector} from "react-redux";
import callApi from "../../../common/api/Apiutils";
import {useAlert} from "../../../common/fieldfactory/alert/AlertContext";
import {
    addNewItem,
    addToAccepted,
    addToBasket,
    addToRegeneratingIndex, clearBasket, completeMeal,
    decreaseCurrentRecipe, deleteLogItem, editLogItemValue,
    increaseCurrentRecipe,
    loadAcceptedPlan, loadNutrition, reloadInventory,
    removeFromAccepted,
    removeFromBasket, removeNewItem,
    resetMealPlan,
    setCuisineToGenerate,
    setDaysToGenerate, setEditItem,
    setErrorGeneratedMealPlan, setFrozenMeal,
    setGenerateBreakfastRecipe,
    setGeneratedMealPlan, setItemType, setLoading,
    setLoadingModalOpen, setMealLoading,
    setMealsPerDay,
    setPage,
    setRecipesToGenerate, setSelectedDate,
    setTargetRecipe,
    setWorldRegion,
    updateMealPlan
} from "./Reducer";


export const cuisines = [
    {name: '0', label: 'Italian'},
    {name: '1', label: 'Mexican'},
    {name: '2', label: 'Chinese'},
    {name: '3', label: 'Japanese'},
    {name: '4', label: 'Indian'},
    {name: '5', label: 'French'},
    {name: '6', label: 'Greek'},
    {name: '7', label: 'Spanish'},
    {name: '8', label: 'Thai'},
    {name: '9', label: 'Vietnamese'},
    {name: '10', label: 'Korean'},
    {name: '11', label: 'Turkish'},
    {name: '12', label: 'Lebanese'},
    {name: '13', label: 'Ethiopian'},
    {name: '14', label: 'Brazilian'},
    {name: '15', label: 'Moroccan'},
    {name: '16', label: 'Caribbean'},
    {name: '17', label: 'American'},
    {name: '18', label: 'British'},
    {name: '19', label: 'German'},
    {name: '20', label: 'Russian'},
    {name: '21', label: 'Peruvian'},
    {name: '22', label: 'Argentinian'},
    {name: '23', label: 'Cuban'},
    {name: '24', label: 'Filipino'},
    {name: '25', label: 'Indonesian'},
    {name: '26', label: 'Malaysian'},
    {name: '27', label: 'Portuguese'},
    {name: '28', label: 'Swedish'},
    {name: '29', label: 'Polish'},
    {name: '30', label: 'Hungarian'},
    {name: '31', label: 'Dutch'},
    {name: '32', label: 'Swiss'},
    {name: '33', label: 'Belgian'},
    {name: '34', label: 'Cajun'},
    {name: '35', label: 'Creole'},
    {name: '36', label: 'Iranian'},
    {name: '37', label: 'Afghan'},
    {name: '38', label: 'Pakistani'},
    {name: '39', label: 'Sri Lankan'},
    {name: '40', label: 'Nepalese'},
    {name: '41', label: 'Tibetan'},
    {name: '42', label: 'Mongolian'},
    {name: '43', label: 'Bangladeshi'},
    {name: '44', label: 'Israeli'},
    {name: '45', label: 'Jordanian'},
    {name: '46', label: 'Palestinian'},
    {name: '47', label: 'Saudi Arabian'},
    {name: '48', label: 'Sudanese'},
    {name: '49', label: 'Kenyan'}
]

export const worldRegions = [
    {name: '0', label: 'North America'},
    {name: '1', label: 'South America'},
    {name: '2', label: 'Europe'},
    {name: '3', label: 'Asia'},
    {name: '4', label: 'Middle East & North Africa'},
    {name: '5', label: 'Sub-Saharan Africa'}
]

export const northAmericaCuisines = [
    {name: '0', label: 'American'},
    {name: '1', label: 'Mexican'},
    {name: '2', label: 'Cajun'},
    {name: '3', label: 'Creole'},
    {name: '4', label: 'Cuban'},
    {name: '5', label: 'Caribbean'}
]

export const southAmericaCuisines = [
    {name: '6', label: 'Brazilian'},
    {name: '7', label: 'Argentinian'},
    {name: '8', label: 'Peruvian'}
]

export const europeCuisines = [
    {name: '9', label: 'Italian'},
    {name: '10', label: 'French'},
    {name: '11', label: 'Spanish'},
    {name: '12', label: 'Greek'},
    {name: '13', label: 'German'},
    {name: '14', label: 'British'},
    {name: '15', label: 'Swedish'},
    {name: '16', label: 'Polish'},
    {name: '17', label: 'Dutch'},
    {name: '18', label: 'Hungarian'},
    {name: '19', label: 'Portuguese'},
    {name: '20', label: 'Swiss'},
    {name: '21', label: 'Belgian'},
    {name: '22', label: 'Russian'}
]

export const asiaCuisines = [
    {name: '23', label: 'Chinese'},
    {name: '24', label: 'Japanese'},
    {name: '25', label: 'Thai'},
    {name: '26', label: 'Vietnamese'},
    {name: '27', label: 'Korean'},
    {name: '28', label: 'Indian'},
    {name: '29', label: 'Pakistani'},
    {name: '31', label: 'Bangladeshi'},
    {name: '32', label: 'Nepalese'},
    {name: '33', label: 'Tibetan'},
    {name: '34', label: 'Mongolian'},
    {name: '35', label: 'Filipino'},
    {name: '36', label: 'Indonesian'},
    {name: '37', label: 'Malaysian'},
    {name: '30', label: 'Sri Lankan'},
]

export const middleEastAndNorthAfricaCuisines = [
    {name: '38', label: 'Lebanese'},
    {name: '39', label: 'Israeli'},
    {name: '40', label: 'Turkish'},
    {name: '42', label: 'Jordanian'},
    {name: '43', label: 'Palestinian'},
    {name: '41', label: 'Iranian'},
    {name: '44', label: 'Saudi Arabian'},
    {name: '45', label: 'Moroccan'}
]

export const subSaharanAfricaCuisines = [
    {name: '46', label: 'Ethiopian'},
    {name: '47', label: 'Kenyan'},
    {name: '48', label: 'Sudanese'}
]

export const getCuisinesByRegion = (regionLabel) => {
    switch (regionLabel) {
        case 0:
            return northAmericaCuisines;
        case 1:
            return southAmericaCuisines;
        case 2:
            return europeCuisines;
        case 3:
            return asiaCuisines;
        case 4:
            return middleEastAndNorthAfricaCuisines;
        case 5:
            return subSaharanAfricaCuisines;
        default:
            return [];
    }
};

export const useReduxActions = () => {
    const handleDeleteFrozenMeal = useDeleteFrozenMeal();
    const handleResetMealPlan = useHandleResetMealPlan();
    const handleSetMealsPerDay = useHandleSetMealsPerDay();
    const handleSetRecipesToGenerate = useHandleSetRecipesToGenerate();
    const handleSetDaysToGenerate = useHandleSetDaysToGenerate();
    const handleSetGenerateBreakfastRecipe = useHandleSetGenerateBreakfastRecipe();
    const handleSetWorldRegion = useHandleSetWorldRegion();
    const handleSetCuisineToGenerate = useHandleSetCuisineToGenerate();
    const handleGeneratePlan = useHandleGeneratePlan();
    const handleAddToBasket = useHandleAddToBasket();
    const handleRemoveFromBasket = useHandleRemoveFromBasket();
    const handleIncreaseCurrentRecipe = useHandleIncreaseCurrentRecipe();
    const handleDecreaseCurrentRecipe = useHandleDecreaseCurrentRecipe();
    const handleAcceptMeal = useHandleAcceptMeal();
    const handleRemoveMeal = useHandleRemoveMeal();
    const handleSetPage = useHandleSetPage();
    const handleAcceptMealPlan = useHandleAcceptMealPlan();
    const handleSetCurrentRecipe = useHandleSetCurrentRecipe();
    const handleRegenerate = useHandleRegenerate();
    const handleUpdateShoppingList = useHandleUpdateShoppingList();
    const handleCompleteMeal = useHandleCompleteMeal();
    const handleNewLogItem = useHandleNewLogItem();
    const handleRemoveLogItem = useHandleRemoveLogItem();
    const handleSetItemType = useHandleSetItemType();
    const handleSetFrozenMeal = useHandleSetFrozenMeal();
    const handleUpdateNutrition = useHandleUpdateNutrition();
    const handleEditLogItem = useHandleEditLogItem();
    const handleEditLogItemValue = useHandleEditLogItemValue();
    const handleDeleteLogItem = useHandleDeleteLogItem();
    const handleUpdateMealLog = useHandleUpdateMealLog();
    const handleSetSelectedDate = useHandleSetSelectedDate();

    return {
        handleDeleteFrozenMeal,
        handleSetSelectedDate,
        handleUpdateMealLog,
        handleEditLogItemValue,
        handleDeleteLogItem,
        handleEditLogItem,
        handleRemoveLogItem,
        handleSetFrozenMeal,
        handleSetItemType,
        handleNewLogItem,
        handleCompleteMeal,
        handleUpdateShoppingList,
        handleRegenerate,
        handleSetCurrentRecipe,
        handleResetMealPlan,
        handleAcceptMealPlan,
        handleSetPage,
        handleSetMealsPerDay,
        handleSetRecipesToGenerate,
        handleSetDaysToGenerate,
        handleSetGenerateBreakfastRecipe,
        handleSetWorldRegion,
        handleSetCuisineToGenerate,
        handleGeneratePlan,
        handleAddToBasket,
        handleRemoveFromBasket,
        handleIncreaseCurrentRecipe,
        handleDecreaseCurrentRecipe,
        handleAcceptMeal,
        handleRemoveMeal,
        handleUpdateNutrition
    };
};

const useDeleteFrozenMeal = () => {
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    const {showAlert} = useAlert();

    return async (uuid) => {
        const queryParams = {uuid: uuid}
        const response = await callApi(`/fit/nutrition/frozen/delete`, 'DELETE', {}, {
            Authorization: authorization,
            service: 'NutritionService'
        }, queryParams, dispatch);
        if (response.error) {
            showAlert(response.message);
        } else {
            dispatch(loadNutrition(response));
        }

    }
}

const useHandleEditLogItem = () => {
    const dispatch = useDispatch();
    return (mIndex, date) => {
        console.log('useHandleEditLogItem')
        dispatch(setEditItem({mIndex, date}));
    }
}

const useHandleEditLogItemValue = () => {
    const dispatch = useDispatch();
    return (e, date, mIndex, index) => {
        dispatch(editLogItemValue({value: e.target.value, date, mIndex, index}));

    }
}


const useHandleDeleteLogItem = () => {
    const dispatch = useDispatch();
    return (date, mIndex, index) => {
        dispatch(deleteLogItem({date, mIndex, index}));
    }
}

const useHandleUpdateNutrition = () => {
    const {meals} = useSelector(state => state.nutrition.logging);
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    const {showAlert} = useAlert();

    return async (index, mIndex, date) => {

        dispatch(setMealLoading({date, mIndex}));

        const updateDateMeal = meals.find(item => item.date === date); // Use find since you're only expecting one match
        const updateBody = [];

        if (!updateDateMeal) return; // Exit early if no meal for the date

        const {logCategories} = updateDateMeal;

        logCategories.forEach(({isAdding, name, order, logItems}) => {
            if (isAdding) {
                logItems.forEach(({type, fmUUID}) => {
                    if (type === 0 && fmUUID) {
                        updateBody.push({
                            name,
                            order,
                            date,
                            type: 'fm',
                            uuid: fmUUID
                        });
                    } else if (type === 1) {
                        updateBody.push({
                            name,
                            order,
                            date,
                            type: 'gm'
                        });
                    }
                });
            }
        });

        if (updateBody.length !== 0) {
            const response = await callApi(`/fit/logging/log`, 'POST', {logs: updateBody}, {
                Authorization: authorization,
                service: 'NutritionService'
            }, undefined, dispatch);
            dispatch(setMealLoading({date, mIndex}));
            if (response.error) {
                showAlert(response.message);
            } else {
                dispatch(loadNutrition(response));
            }
        }
    };
};

export default useHandleUpdateNutrition;


const useHandleSetFrozenMeal = () => {
    const dispatch = useDispatch();
    return (e, index, mIndex, date) => {
        dispatch(setFrozenMeal({e, index, mIndex, date}))
    }
}

const useHandleSetItemType = () => {
    const dispatch = useDispatch();
    return (e, index, mIndex, date) => {
        dispatch(setItemType({e, index, mIndex, date}));
    }
}

const useHandleNewLogItem = () => {
    const dispatch = useDispatch();
    return (mIndex, date) => {
        dispatch(addNewItem({index: mIndex, date}));
    }
}

const useHandleRemoveLogItem = () => {
    const dispatch = useDispatch();
    return (mIndex, date) => {
        console.log('useHandleRemoveLogItem')
        dispatch(removeNewItem({index: mIndex, date}));
    }
}

const useHandleCompleteMeal = () => {
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    const {showAlert} = useAlert();
    return async (recipe) => {
        const response = await callApi(`/fit/nutrition/frozen`, 'PUT', {name: recipe}, {
            Authorization: authorization,
            service: 'NutritionService'
        }, undefined, dispatch);
        if (response.error) {
            showAlert(response.message);
        } else {
            dispatch(completeMeal(recipe))
            dispatch(reloadInventory(response))
        }
        // dispatch(completeMeal(recipe))
    }
}

const useHandleResetMealPlan = () => {
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    const {showAlert} = useAlert();
    return async () => {
        const response = await callApi(`/fit/nutrition/reset`, 'DELETE', {}, {
            Authorization: authorization,
            service: 'NutritionService'
        }, undefined, dispatch);
        if (response.error) {
            showAlert(response.message);
        } else {
            dispatch(resetMealPlan())
        }
    }
}

const useHandleUpdateShoppingList = () => {
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    const {showAlert, hideAlert} = useAlert();
    const {
        shoppingList,
    } = useSelector(state => state.nutrition);
    const {
        inBasket,
        notInBasket
    } = shoppingList;
    return async () => {
        const newButtons = [
            {
                label: "Yes",
                onClick: async () => {
                    const response = await callApi('/fit/nutrition/shopping', 'PUT', {shoppingCart: inBasket}, {
                        Authorization: authorization,
                        service: 'NutritionService'
                    }, undefined, dispatch)
                    if (response.error) {
                        showAlert(response.message);
                    } else {
                        dispatch(clearBasket());
                        if (notInBasket.length === 0) {
                            dispatch(setPage('ALL'))
                        }
                    }
                }
            },
            {label: "No", onClick: hideAlert}
        ];
        showAlert('Are you sure you want to update the shopping list?', newButtons);
    }

}

const useHandleRegenerate = () => {
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    const {showAlert} = useAlert();
    return async (mealName, index) => {
        dispatch(addToRegeneratingIndex(index))
        const response = await callApi(`/fit/nutrition/regenerate`, 'PUT', {mealName: mealName}, {
            Authorization: authorization,
            service: 'NutritionService'
        }, undefined, dispatch);
        if (response.error) {
            showAlert(response.message);
        } else {
            dispatch(updateMealPlan({mealName: mealName, response: response, index: index}));
        }
    }
}

const useHandleAcceptMeal = () => {
    const dispatch = useDispatch();
    return (name) => {
        dispatch(addToAccepted({name: name}));
    }
}

const useHandleRemoveMeal = () => {
    const dispatch = useDispatch();
    return (name) => {
        dispatch(removeFromAccepted({name: name}));
    }
}

const useHandleSetPage = () => {
    const dispatch = useDispatch();
    return (page) => {
        dispatch(setPage(page));
    }
}

const useHandleSetSelectedDate = () => {
    const dispatch = useDispatch();
    return (date) => {
        dispatch(setSelectedDate(date.toLocaleDateString('en-CA')));
    }
}

const useHandleUpdateMealLog = () => {
    const {meals} = useSelector(state => state.nutrition.logging);
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    const {showAlert} = useAlert();
    return async (mIndex, date) => {
        dispatch(setMealLoading({date, mIndex}));
        const meal = meals.find(item => item.date === date);
        console.log(meal)
        const logCategory = meal.logCategories[mIndex];
        const body = {
            date: date,
            mealName: logCategory.name,
            order: logCategory.order,
            deletedMeals: [],
            updatedItems: []
        };
        logCategory.logItems.forEach((item) => {
            if (item.deletedItem) {
                if (item.mealId) {
                    body.deletedMeals.push({id: item.mealId})
                } else {
                    body.deletedMeals.push({name: item.name})
                }

            } else if (item.updatedValue) {
                body.updatedItems.push({name: item.name, value: item.updatedValue})
            }
        })

        // Simulate API call and wait for the response
        if (body.deletedMeals.length !== 0 || body.updatedItems.length !== 0) {
            const response = await callApi(`/fit/logging/update`, 'PUT', body, {
                Authorization: authorization,
                service: 'NutritionService'
            }, undefined, dispatch);
            dispatch(setMealLoading({date, mIndex}));
            if (response.error) {
                showAlert(response.message);
            } else {
                dispatch(loadNutrition(response));
            }
        }

    }
}

const useHandleAcceptMealPlan = () => {
    const {accepted} = useSelector(state => state.nutrition.generatedMealPlan);
    const dispatch = useDispatch();
    const authorization = useSelector(state => state.default.authorization);
    const {showAlert} = useAlert();
    return async () => {
        dispatch(setPage('ALL'))
        dispatch(setLoading())
        const response = await callApi(`/fit/nutrition/confirm`, 'POST', {recipes: accepted}, {
            Authorization: authorization,
            service: 'NutritionService'
        }, undefined, dispatch);
        if (response.error) {
            showAlert(response.message);
        } else {
            dispatch(loadAcceptedPlan(response))
        }
    }
}

const useHandleIncreaseCurrentRecipe = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch(increaseCurrentRecipe());
    }
}

const useHandleSetCurrentRecipe = () => {
    const dispatch = useDispatch();
    return (e) => {
        dispatch(setTargetRecipe(e));
    }
}


const useHandleDecreaseCurrentRecipe = () => {
    const dispatch = useDispatch();
    return () => {
        dispatch(decreaseCurrentRecipe());
    }
}

const useHandleAddToBasket = () => {
    const dispatch = useDispatch();
    return (name) => {
        dispatch(addToBasket({name: name}));
    }
}

const useHandleRemoveFromBasket = () => {
    const dispatch = useDispatch();
    return (name) => {
        dispatch(removeFromBasket({name: name}));
    }
}

const useHandleGeneratePlan = () => {
    const {
        recipesToGenerate,
        daysToGenerate,
        worldRegion,
        cuisineToGenerate
    } = useSelector(state => state.nutrition.generation);
    const {nutrition} = useSelector(state => state.setting);
    const {categories} = nutrition;


    const cuisine = getCuisinesByRegion(worldRegion)[cuisineToGenerate]?.label;
    const authorization = useSelector(state => state.default.authorization);
    const {showAlert} = useAlert();
    const dispatch = useDispatch();
    const body = {
        "days": daysToGenerate + 5,
        "mealsPerDay": categories.length,
        "generateBreakfast": false,
        "recipes": recipesToGenerate + 1,
        "cuisine": cuisine
    }
    return async () => {
        dispatch(setLoadingModalOpen(true))
        const response = await callApi(`/fit/nutrition/plan`, 'POST', body, {
            Authorization: authorization,
            service: 'NutritionService'
        }, undefined, dispatch);
        if (response.error) {
            dispatch(setErrorGeneratedMealPlan())
            showAlert(response.message);
        } else {
            dispatch(setGeneratedMealPlan(response));
        }

    }
}

// Hook to handle setting meals per day
const useHandleSetMealsPerDay = () => {
    const dispatch = useDispatch();
    return (mealsPerDay) => {
        dispatch(setMealsPerDay(mealsPerDay));
    };
};

// Hook to handle setting recipes to generate
const useHandleSetRecipesToGenerate = () => {
    const dispatch = useDispatch();
    return (recipesToGenerate) => {
        dispatch(setRecipesToGenerate(recipesToGenerate));
    };
};

// Hook to handle setting days to generate
const useHandleSetDaysToGenerate = () => {
    const dispatch = useDispatch();
    return (daysToGenerate) => {
        dispatch(setDaysToGenerate(daysToGenerate));
    };
};

// Hook to handle setting whether to generate breakfast recipe
const useHandleSetGenerateBreakfastRecipe = () => {
    const dispatch = useDispatch();
    return (generateBreakfastRecipe) => {
        dispatch(setGenerateBreakfastRecipe(generateBreakfastRecipe));
    };
};

// Hook to handle setting world region
const useHandleSetWorldRegion = () => {
    const dispatch = useDispatch();
    return (worldRegion) => {
        dispatch(setWorldRegion(worldRegion));
    };
};

// Hook to handle setting cuisine to generate
const useHandleSetCuisineToGenerate = () => {
    const dispatch = useDispatch();
    return (cuisineToGenerate) => {
        dispatch(setCuisineToGenerate(cuisineToGenerate));
    };
};
