import {createSlice} from '@reduxjs/toolkit';


const initialState = {
    page: 'ALL',
    selectedDate: new Date().toLocaleDateString('en-CA'),
    generation: {
        mealsPerDay: 0,
        recipesToGenerate: 0,
        daysToGenerate: 0,
        generateBreakfastRecipe: 0,
        worldRegion: 0,
        cuisineToGenerate: 0,
        loadingModalOpen: false,
    },
    goals: {
        proteinGoal: 0,
        fatGoal: 0,
        carbGoal: 0,
        caloriesGoal: 0,
    },
    consumed: {
        proteinConsumed: 0,
        fatConsumed: 0,
        carbsConsumed: 0,
        caloriesConsumed: 0,
    },
    dataLoaded: false,
    animateGraph : true,
    generatedMealPlan: {
        regeneratingIndexes: [],
        mealPlanName: '',
        accepted: [],
        awaitingAcceptance: []
    },
    shoppingList: {
        inBasket: [],
        notInBasket: []
    },
    cookingRecipes: {
        cuisine: '',
        currentRecipe: 0,
        recipes: []
    },
    generating: false,
    logging: {
        meals: [],
        frozenMealInventory: []
    }
}

export const nutritionSlice = createSlice({
    initialState,
    name: 'nutrition',
    reducers: {
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setMealLoading : (state, action) => {
            const {date,mIndex} = action.payload;
            const meal = state.logging.meals.find(item => item.date === date);
            const logCategory = meal.logCategories[mIndex];
            if (logCategory) {
                logCategory.isGenerating = logCategory.isGenerating === undefined ? true : !logCategory.isGenerating;
            }
        },
        setItemType: (state, action) => {
            const {e, index, mIndex, date} = action.payload;

            // Find the correct meal by its index (mIndex) and category by categoryIndex
            const meal = state.logging.meals.find(item => item.date === date);
            const logCategory = meal.logCategories[mIndex];

            if (logCategory) {
                // Update the specific logItem's type field
                logCategory.logItems[index].type = e;

                // Reset the fmUUID field
                logCategory.logItems[index].fmUUID = undefined;
            }
        },
        editLogItemValue:(state, action) => {
            const {value ,date,mIndex,index} = action.payload;

            // Find the correct meal by its index (mIndex) and category by categoryIndex
            const meal = state.logging.meals.find(item => item.date === date);
            const logCategory = meal.logCategories[mIndex];

            if (logCategory) {
                // Update the specific logItem's type field
                logCategory.logItems[index].updatedValue = value;
            }
        },
        deleteLogItem:(state, action) => {
            const {date,mIndex,index} = action.payload;


            // Find the correct meal by its index (mIndex) and category by categoryIndex
            const meal = state.logging.meals.find(item => item.date === date);
            const logCategory = meal.logCategories[mIndex];

            if (logCategory) {
                // Update the specific logItem's type field
                logCategory.logItems[index].deletedItem = true;
            }
        },
        removeNewItem: (state, action) => {
            const {date, index} = action.payload; // Assume categoryIndex is the index of the log category

            // Find the meal that matches the given date
            const meal = state.logging.meals.find(item => item.date === date);

            if (meal) {
                // Access the log category by its index
                const logCategory = meal.logCategories[index];

                if (logCategory) {
                    // Remove items from the logItems array that have newItem set to true
                    logCategory.logItems = logCategory.logItems.filter(item => !item.newItem);

                    // Optionally, you can mark that no new items are being added anymore
                    logCategory.isAdding = false;
                }
            }
        },
        setFrozenMeal: (state, action) => {
            const {e, index, mIndex, date} = action.payload; // Include categoryIndex in the payload

            // Find the correct meal by its index (mIndex) and category by categoryIndex
            const meal = state.logging.meals.find(item => item.date === date);
            const logCategory = meal.logCategories[mIndex];
            if (logCategory) {
                // Update the specific logItem's fmUUID field
                logCategory.logItems[index].fmUUID = e;
            }
        },
        setEditItem: (state, action) => {
            const {mIndex, date} = action.payload;
            const meal = state.logging.meals.find(item => item.date === date);
            if (meal) {
                // Access the log category by its index
                const logCategory = meal.logCategories[mIndex];
                console.log(logCategory)
                logCategory.logItems.forEach(item => {
                    item.updatedValue = undefined;
                    item.deletedItem = undefined;
                });
                logCategory.isEditing = logCategory.isEditing === undefined ? true : !logCategory.isEditing;
            }
        },
        addNewItem: (state, action) => {
            const {date, index} = action.payload;

            // Find the meal that matches the given date
            const meal = state.logging.meals.find(item => item.date === date);

            if (meal) {
                // Access the log category by its index
                const logCategory = meal.logCategories[index];

                if (logCategory) {
                    // Add new item to the logItems array in the correct category
                    logCategory.logItems.push({
                        type: '',
                        name: '',
                        protein: 0,
                        fat: 0,
                        carbs: 0,
                        calories: 0,
                        newItem: true
                    });

                    logCategory.isAdding = true;
                }
            }
        },
        completeMeal: (state, action) => {
            const index = state.cookingRecipes.recipes.findIndex(item => item.recipe === action.payload);
            if (index !== -1) {
                state.cookingRecipes.recipes.splice(index, 1);

                if (state.cookingRecipes.recipes.length === 0) {
                    state.page = 'ALL';
                }
            }
        },
        clearBasket: (state) => {
            state.shoppingList.inBasket = [];
        },
        setAnimateGraph: (state) => {
            state.animateGraph = true;
        },
        setLoading: (state) => {
            state.generating = true;
        },
        loadAcceptedPlan: (state, action) => {
            const {planName, instructions, shoppingList} = action.payload;
            state.generating = false;
            state.shoppingList.notInBasket = shoppingList;
            state.cookingRecipes.cuisine = planName;
            state.cookingRecipes.recipes = instructions;
        },
        resetMealPlan: (state) => {
            state.generatedMealPlan.accepted = [];
            state.generatedMealPlan.awaitingAcceptance = [];
        },
        addToRegeneratingIndex: (state, action) => {
            state.generatedMealPlan.regeneratingIndexes.push(action.payload);
        },
        updateMealPlan: (state, action) => {
            const {mealName, response, index} = action.payload;
            const awIndex = state.generatedMealPlan.awaitingAcceptance.findIndex(item => item.name === mealName);
            if (awIndex !== -1) {
                state.generatedMealPlan.awaitingAcceptance[awIndex] = response;
                state.generatedMealPlan.regeneratingIndexes = state.generatedMealPlan.regeneratingIndexes.filter(item => item !== index);
            }
        },
        setPage: (state, action) => {
            state.animateGraph = false;
            state.page = action.payload;
            if(action.payload === 'ALL') {
                state.selectedDate = new Date().toLocaleDateString('en-CA');
            }
        },
        setTargetRecipe: (state, action) => {
            state.cookingRecipes.currentRecipe = action.payload;
        },
        increaseCurrentRecipe: (state) => {
            if (state.cookingRecipes.currentRecipe < state.cookingRecipes.recipes.length - 1) {
                state.cookingRecipes.currentRecipe += 1;
            }
        },
        decreaseCurrentRecipe: (state) => {
            if (state.cookingRecipes.currentRecipe > 0) {
                state.cookingRecipes.currentRecipe -= 1;
            }
        },
        addToAccepted: (state, action) => {
            const index = state.generatedMealPlan.awaitingAcceptance.findIndex(item => item.name === action.payload.name);
            if (index !== -1) {
                const [item] = state.generatedMealPlan.awaitingAcceptance.splice(index, 1);
                state.generatedMealPlan.accepted.push(item);
            }
        },
        removeFromAccepted: (state, action) => {
            const index = state.generatedMealPlan.accepted.findIndex(item => item.name === action.payload.name);
            if (index !== -1) {
                const [item] = state.generatedMealPlan.accepted.splice(index, 1);
                state.generatedMealPlan.awaitingAcceptance.push(item);
            }
        },
        addToBasket: (state, action) => {
            const index = state.shoppingList.notInBasket.findIndex(item => item.name === action.payload.name);

            if (index !== -1) {
                // Move the item from notInBasket to inBasket
                const [item] = state.shoppingList.notInBasket.splice(index, 1);
                state.shoppingList.inBasket.push(item);
            }
        },
        removeFromBasket: (state, action) => {
            const index = state.shoppingList.inBasket.findIndex(item => item.name === action.payload.name);

            if (index !== -1) {
                // Move the item from inBasket to notInBasket
                const [item] = state.shoppingList.inBasket.splice(index, 1);
                state.shoppingList.notInBasket.push(item);
            }
        },
        setMealsPerDay: (state, action) => {
            state.generation.mealsPerDay = action.payload;
        },
        setRecipesToGenerate: (state, action) => {
            state.generation.recipesToGenerate = action.payload;
        },
        setDaysToGenerate: (state, action) => {
            state.generation.daysToGenerate = action.payload;
        },
        setGenerateBreakfastRecipe: (state, action) => {
            state.generation.generateBreakfastRecipe = action.payload;
        },
        setWorldRegion: (state, action) => {
            state.generation.worldRegion = action.payload;
        },
        setCuisineToGenerate: (state, action) => {
            state.generation.cuisineToGenerate = action.payload;
        },
        loadNutrition: (state, action) => {
            const {
                proteinGoal,
                proteinConsumed,
                fatGoal,
                fatConsumed,
                carbGoal,
                carbConsumed,
                caloriesConsumed,
                calorieGoal,
                mealPlanGenerated,
                mealPlanAcceptance,
                frozenMealInventoryDTO,
                nutritionLogDayDTOList
            } = action.payload;
            state.goals.proteinGoal = proteinGoal;
            state.consumed.proteinConsumed = proteinConsumed;
            state.goals.carbGoal = carbGoal;
            state.consumed.carbsConsumed = carbConsumed;
            state.goals.caloriesGoal = calorieGoal;
            state.consumed.caloriesConsumed = caloriesConsumed;
            state.goals.fatGoal = fatGoal;
            state.consumed.fatConsumed = fatConsumed;
            state.dataLoaded = true;
            if (frozenMealInventoryDTO) {
                state.logging.frozenMealInventory = frozenMealInventoryDTO.frozenMeals;
            }
            if (mealPlanGenerated) {
                const {planName, instructions, shoppingList} = mealPlanGenerated;
                state.shoppingList.notInBasket = shoppingList;
                state.cookingRecipes.cuisine = planName;
                state.cookingRecipes.recipes = instructions;
            }
            if (mealPlanAcceptance) {
                const {mealPlanName, recipes} = mealPlanAcceptance;
                state.generatedMealPlan.awaitingAcceptance = recipes;
                state.generatedMealPlan.mealPlanName = mealPlanName;
                state.generation.loadingModalOpen = false;
            }
            if (nutritionLogDayDTOList) {
                // Iterate through each log day and sort logCategories by the "order" field
                nutritionLogDayDTOList.forEach(logDay => {
                    logDay.logCategories = logDay.logCategories.sort((a, b) => a.order - b.order);
                });

                state.logging.meals = nutritionLogDayDTOList;
            }

        },
        setDataLoaded: (state, action) => {
            state.dataLoaded = action.payload;
        },
        setErrorGeneratedMealPlan: (state) => {
            state.generation.loadingModalOpen = false;
        },
        setGeneratedMealPlan: (state, action) => {
            const {mealPlanName, recipes} = action.payload;
            state.generatedMealPlan.awaitingAcceptance = recipes;
            state.generatedMealPlan.mealPlanName = mealPlanName;
            state.generation.loadingModalOpen = false;
        },
        setLoadingModalOpen: (state, action) => {
            state.generation.loadingModalOpen = action.payload;
        },
        reloadInventory: (state, action) => {
            state.logging.frozenMealInventory = action.payload.frozenMeals;
        }
    }

});

export const {
    setAnimateGraph,
    setFrozenMeal,
    removeNewItem,
    setItemType,
    addNewItem,
    reloadInventory,
    completeMeal,
    clearBasket,
    setLoading,
    addToRegeneratingIndex,
    updateMealPlan,
    setTargetRecipe,
    loadAcceptedPlan,
    resetMealPlan,
    addToAccepted,
    removeFromAccepted,
    setPage,
    addToBasket,
    removeFromBasket,
    setMealsPerDay,
    setRecipesToGenerate,
    setDaysToGenerate,
    setGenerateBreakfastRecipe,
    setWorldRegion,
    setCuisineToGenerate,
    loadNutrition,
    setDataLoaded,
    setGeneratedMealPlan,
    setLoadingModalOpen,
    increaseCurrentRecipe,
    decreaseCurrentRecipe,
    setErrorGeneratedMealPlan,
    setEditItem,
    editLogItemValue,
    deleteLogItem,
    setMealLoading,
    setSelectedDate
} = nutritionSlice.actions;

export default nutritionSlice.reducer;