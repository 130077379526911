import React from 'react';
import {FaMinus, FaPlus} from "react-icons/fa";
import {useSelector} from "react-redux";
import {Button, Card, IconButton} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";


const FrozenMeals = () => {
    const {logging} = useSelector(state => state.nutrition)
    const {frozenMealInventory} = logging;
    const {handleDeleteFrozenMeal} = useReduxActions();
    if(!frozenMealInventory || frozenMealInventory.length === 0) return null;
    return (
        <Card header={'Frozen meal inventory'}>
            {frozenMealInventory.map((item, index) => (
                <FrozenMealComponent meal={item} key={index} index={index} handleDeleteFrozenMeal={handleDeleteFrozenMeal}/>
            ))}
        </Card>
    );
}

export default FrozenMeals;

const FrozenMealComponent = ({
                                 meal, index,handleDeleteFrozenMeal
                             }) => {
    const {
        calories,
        carbohydrates,
        expirationDate,
        fats,
        name,
        protein,
        remainingPortions,
        frozenMealId
    } = meal;
    console.log(meal)
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            {index !== 0 && <div className="line-relative"/>}
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                <h4 style={index !== 0 ? {marginTop: 0} : {}}>{name}</h4>
                <IconButton onClick={() => setOpen(!open)} icon={open ? <FaMinus/> : <FaPlus/>}/>
            </div>
            <li><strong>Calories:</strong> {calories} kcal</li>
            <li><strong>Remaining Portions:</strong> {remainingPortions}</li>
            {open && <>
                <li><strong>Carbohydrates:</strong> {carbohydrates} g</li>
                <li><strong>Fats:</strong> {fats} g</li>
                <li><strong>Protein:</strong> {protein} g</li>
                <li><strong>Expiration Date:</strong> {expirationDate}</li>
                <Button label="Delete meals" className="button mt-10" onClick={() => handleDeleteFrozenMeal(frozenMealId)}/>
            </>}
        </div>
    );
}