import React from 'react';
import {useSelector} from "react-redux";
import {Page} from "../../common/fieldfactory";
import ExerciseGoalsComponent from "./components/ExerciseGoalsComponent";
import WeightGoalComponent from "./components/WeightGoal";

const Goals = () => {
    const {page} = useSelector(state => state.goal);
    const getPageComponent = () => {
        switch (page) {
            case 'ALL':
                return <>
                    <WeightGoalComponent/>
                    <ExerciseGoalsComponent/>
                </>;
            case 'WEIGHT':
                return <WeightGoalComponent/>;

            default: <></>
        }
    }
    return (
        <Page header="Goals">
            {getPageComponent()}
        </Page>
    );
}

export default Goals;