import React from 'react';
import {useSelector} from "react-redux";
import {Card} from "../../../common/fieldfactory";
import GoalDoughnutGraph from "./GoalDoughnutGraph";

const ExerciseGoalsComponent = () => {
    const {oneRepMaxGoals} = useSelector(state => state.goal);
    return (
        <>
            {oneRepMaxGoals.map(goal => (
                <ExerciseGoalComponent key={goal.oneRepMaxName} goal={goal}/>
            ))}
        </>
    )
}

const ExerciseGoalComponent = (props) => {
    const {
        oneRepMaxName,
        originalValue,
        oneRepMaxGoal,
        currentValue,
        calculatedValues
    } = props.goal;

    const {mobile} = useSelector(state => state.default);
    const {unit} = useSelector(state => state.setting);
    const doughnutGraphSize = mobile ? '120px' : '250px';
    const graphSizeScale = mobile ? 75 : 75;
    const graphSize = graphSizeScale * (2+calculatedValues.length)
    const cardHeader = oneRepMaxName +' goal'
    const progress = Math.ceil(((originalValue - currentValue) / (originalValue - oneRepMaxGoal)) * 100);
    const labels = ['Goal', 'Current'];
    const data = [oneRepMaxGoal, currentValue];

    calculatedValues.forEach(calculatedValue => {
        const {name, date , value} = calculatedValue;
        const label = name+' '+date
        labels.push(label);
        data.push(value);
    })
    console.log(labels)
    const dataSet = [{
        label: 'Value',
        data: data,
        borderColor: ['rgb(54, 162, 235)'],
        backgroundColor: ['rgb(54, 162, 235)'],
    }]

    const style = {marginTop: '5px', marginBottom: '5px'};
    const valueDisplay = () => {
        return (!mobile ? <div><h2 style={style}>{progress}%</h2><h3 style={style}>Progress</h3></div> :
                <div><h4 style={style}>{progress}%</h4><h5 style={style}>Progress</h5></div>
        )
    }
    const formatedGoalAtSetting = Number(originalValue).toFixed(2);
    const formatedCurrent = Number(currentValue).toFixed(2);
    const formatedGoal = Number(oneRepMaxGoal).toFixed(2);


    const maxScale =  Math.ceil(parseFloat(oneRepMaxGoal) +10);
    const xScale = {
        min: 0, // Set minimum value for the scale
        max: maxScale,  // Set maximum value for the scale
        ticks: {
            autoSkip: false,
            // Define the exact values for the X-axis ticks
            callback: function (value) {
                return `${value} ${unit}`; // Custom format for the labels
            },
            // Use a custom array of tick values
            values: [-1, -0.75, -0.5, -0.25, 0, 0.25, 0.5, 0.75, 1],
        },
    };


    return (
        <Card header={cardHeader}>
            <h5 style={{marginTop: '5px'}}>{oneRepMaxName} goal progress</h5>
            <GoalDoughnutGraph
                doughnutGraphSize={doughnutGraphSize}
                mobile={mobile}
                progress={progress}
                valueDisplay={valueDisplay()}
                graphSize={graphSize}
                formatedGoalAtSetting={formatedGoalAtSetting}
                unit={unit}
                formatedCurrent={formatedCurrent}
                formatedGoal={formatedGoal}
                xScale={xScale}
                dataSet={dataSet}
                labels={labels}
                doughnutLabel={'max'}
                barGraphTitle={'Max goal chart'}
            />
        </Card>
    )
}

export default ExerciseGoalsComponent;