import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Button, ButtonRow, Card, DatePicker, TextBox} from "../../../common/fieldfactory";
import {useReduxActions} from "../logic/Events";
import WeightGraph from "./linegraph/WeightGraph";

const WeightComponent = () => {
    const [weight, setWeight] = useState(false);
    const {mobile} = useSelector(state => state.default);
    const {handleSetPage} = useReduxActions();
    const [expandedComponent, setExpandedComponent] = useState(!mobile);
    const [selected, setSelected] = useState(2);
    const {unit} = useSelector(state => state.setting);
    const {handleSetLoggedWeight, handelSetNewWeightDate, handleSaveNewWeightData} = useReduxActions();
    const {page,newWeight, weightData} = useSelector(state => state.home);
    const {dateLogged, weightLogged} = newWeight;

    const buttons = [
        {name: '0', label: 'D'},
        {name: '1', label: 'W'},
        {name: '2', label: 'M'},
        {name: '3', label: 'Y'}
    ];

    const handleCardClick = () => {
        if (mobile) {
            setExpandedComponent(prevState => !prevState);
            if (expandedComponent) {
                handleSetPage('ALL');
            } else {
                handleSetPage('WEIGHT');
            }
        }
    };

    useEffect(() => {
        if (mobile) {
            setExpandedComponent(false);
        } else {
            setExpandedComponent(true);
        }
    }, [mobile]);

    useEffect(() => {
        if (mobile) {
            if (page === 'WEIGHT') {
                setExpandedComponent(true);
            } else if (page === 'ALL') {
                setExpandedComponent(false);
            }
        }
    }, [mobile, page]);

    const cardHeader = page === 'WEIGHT' ? selected === 2 ? 'Monthly average weight': selected === 1 ? 'Weekly average weight': selected === 0 ? 'Daily weight': selected === 3 ? 'Average weight': 'Progress graph' : 'Weight';

    return (
        <Card
            header={cardHeader}
            onClick={mobile && !expandedComponent ? handleCardClick : null}
            expandable={true}
            expanded={expandedComponent}
            collapseOnClick={handleCardClick}
        >
            {expandedComponent && (
                <>
                    {weightData.currentWeight !== '' && (
                        <>
                            <ButtonRow
                                onClick={(e) => setSelected(e)}
                                buttons={buttons}
                                label={'test'}
                                buttonSelected={selected}
                            />
                            <WeightGraph xScale={selected} weightData={weightData}/>
                        </>
                    )}
                    {!weight && (
                        <Button
                            label={'Add data'}
                            className={'button mt-10'}
                            onClick={() => setWeight(!weight)}
                        />
                    )}
                    {weight && (
                        <>
                            <div className="flex-row-container">
                                <div className="flex-item-half">
                                    <TextBox
                                        label={'Weight'}
                                        className="form-control"
                                        value={weightLogged}
                                        onChange={handleSetLoggedWeight}
                                    />
                                </div>
                                <div className="flex-item-half">
                                    <DatePicker
                                        label={'Date'}
                                        highlightToday={true}
                                        value={dateLogged}
                                        onChange={handelSetNewWeightDate}
                                    />
                                </div>
                            </div>
                            <Button
                                label={'Save'}
                                className={'button mt-10'}
                                onClick={() => handleSaveNewWeightData(setWeight)}
                            />
                        </>
                    )}
                </>
            )}
            {!expandedComponent && (
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {weightData.currentWeight !== '' ? (<>
                            <h4 style={{marginBottom: 0}}>{weightData.currentWeight} {unit}</h4>
                            <h5 style={{marginBottom: 0}}>{weightData.currentWeightDateLogged}</h5>
                        </>
                    ) : (
                        <h4 style={{marginBottom: 0}}>No Data</h4>
                    )}
                </div>
            )}
        </Card>
    );
};

export default WeightComponent;
