import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    weightGoal: '',
    goalWeight: '',
    page: 'ALL',
    weightAtGoalSetting: '',
    weightGoalDropDown: [],
    oneRepMaxGoals: [],
};

const goalSlice = createSlice({
    name: 'goal',
    initialState,
    reducers: {
        setGoalWeight: (state, action) => {
            state.goalWeight = action.payload;
        },
        setGoals: (state, action) => {
            state.weightGoal = action.payload;
        },
        loadGoals: (state, action) => {
            const {goalWeight, weightGoal, goalDropDown, weightAtGoalSetting, oneRepMaxGoals} = action.payload;
            state.goalWeight = goalWeight;
            state.weightGoal = weightGoal;
            state.weightGoalDropDown = goalDropDown;
            state.weightAtGoalSetting = weightAtGoalSetting;
            state.oneRepMaxGoals = oneRepMaxGoals;
        }
    }
});

export const {setGoals, loadGoals, setGoalWeight} = goalSlice.actions;

export default goalSlice.reducer;